export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const shopbg = require("../assets/shopbg.png");
export const mehndiImg = require("../assets/mehndiImg.png");
export const haldiImg = require("../assets/haldiImg.png");
export const weddingImg = require("../assets/weddingImg.png");
export const jewllery = require("../assets/jewellery.png");
export const bangles = require("../assets/bangles.png");
export const accessories = require("../assets/accessories.png");
export const footwear = require("../assets/footwear.png");
export const garland = require("../assets/garland.png");
export const arrivalbg = require("../assets/arrivalbg.png");
export const shoppingImg1 = require("../assets/shoppingimg2.png");
export const shoppingImg2 = require("../assets/shoppingimg3.png");
export const shoppingImg3 = require("../assets/shoppingimg4.png");
export const shoppingImg4 = require("../assets/shoppingimg5.png");



