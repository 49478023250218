import React from "react";
// Customizable Area Start
import { Box, Typography, Button, styled, CardActionArea, CardContent, CardActions, Card, Rating } from "@mui/material";
import { shopbg, mehndiImg, haldiImg, weddingImg, jewllery, footwear, bangles, accessories, garland, arrivalbg, shoppingImg1, shoppingImg2, shoppingImg3, shoppingImg4 } from "./assets";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import HeaderFooterLayout from "../../../../packages/components/src/HeaderFooterLayout";
// Customizable Area End

import ShopController, {
  Props,
} from "./ShopController.web";

export default class Shop extends ShopController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  images = [
    { src: mehndiImg, alt: "Mehendi Image",name:"Mehendi" },
    { src: haldiImg, alt: "Haldi Image",name:"Haldi" },
    { src: weddingImg, alt: "Wedding Image",name:"Wedding" },
  ];

  items = [
    { src: jewllery, label: 'Jewellery' },
    { src: garland, label: 'Garland' },
    { src: footwear, label: 'Footwear' },
    { src: bangles, label: 'Bangles' },
    { src: accessories, label: 'Other accessories' },
  ];

  products = [
    {
      image: shoppingImg2,
      title: 'Lehenga',
      price: '₹ 46,000',
      location: 'Mumbai',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
      image: shoppingImg4,
      rating: 1,
      title: 'Lehenga',
      price: '₹ 46,000',
      location: 'Mumbai',
      ratingText: '7.5 (678 Ratings)',
    },
    {
      location: 'Mumbai',
      image: shoppingImg1,
      price: '₹ 46,000',
      title: 'Lehenga',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
      image: shoppingImg3,
      price: '₹ 46,000',
      title: 'Lehenga',
      location: 'Mumbai',
      ratingText: '7.5 (678 Ratings)',
      rating: 1,
     
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
          <HeaderFooterLayout navigation={this.props.navigation}>
            <CustomBox>
              <Box className="mainBox">
                <Box className='shopImgBox'>
                  <img className="shopbgImg"
                    src={shopbg} alt="Shop background" />
                  <Box className='imgtextBox'>
                    <Typography className="imgMainText">
                      Shop For Your Wedding
                    </Typography>
                    <Typography className="imgDescText">
                      Guiding you towards crafting timeless moments: Curated <br /> wedding planning tailored to your love story
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3rem 0rem" }}>
                  <Typography className="responsiveText">
                    Planning a wedding is an exciting journey filled with countless details and memorable moments. At our Wedding Essentials Shop, we understand that every couple <br /> dreams of a perfect day that reflects their unique love story. That's why we're dedicated to providing a curated selection of high-quality products to make your <br /> wedding planning process smooth, stress-free, and, most importantly, memorableFrom elegant decorations and beautiful stationery to stylish accessories and <br /> thoughtful favors, our shop offers everything you need to create the wedding of your dreams. Whether you’re looking for timeless classics or trendy new designs, our <br /> extensive range ensures you’ll find the perfect items to suit your style and vision.
                  </Typography>
                </Box>
                <Typography style={{ fontSize: "24px", fontFamily: "poppins", fontWeight: 600, textAlign: "center" }}>Browse by function</Typography>
                <Box sx={{ width: "100vw", display: "flex", justifyContent: "space-evenly", paddingTop: "2rem", alignItems: "center" }}>
                  <ArrowBackIosIcon />
                  {this.images.map((image, index) => (
                    <img className='functionImg' onClick={()=>this.showFunctionDress(image.name)} key={index} src={image.src} alt={image.alt} />
                  ))}
                  <ArrowForwardIosIcon />
                </Box>
                <Typography style={{ fontSize: "24px", fontFamily: "poppins", fontWeight: 600, textAlign: "center", paddingTop: "2rem" }}>Shop All</Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: "3rem 0rem" }}>
                  {this.items.map((item, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 1rem" }}>
                      <img className='shopsImg' src={item.src} alt={item.label} />
                      <Typography className='shopsImgText'>
                        {item.label}
                      </Typography>
                    </Box>
                  ))}
                  <ArrowForwardIosIcon className="arrowIcon" />
                </Box>

                <Box className="newArrivalsBox">
                  <img className="shopbgImg" src={arrivalbg} alt="arrivalbg" />

                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "15%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Typography style={{ fontSize: "32px", fontWeight: 400, fontFamily: "timesNewRoman", color: "white" }}>
                      NEW ARRIVALS
                    </Typography>
                    <Typography style={{ fontSize: "128px", fontWeight: 700, fontFamily: "timesNewRoman", color: "white" }}>50%<span style={{ fontSize: "76px", fontWeight: 700, fontFamily: "timesNewRoman", color: "white" }}>OFF</span></Typography>
                    <Button className="shopNowButton">SHOP NOW</Button>
                    <Typography className="termsConditions">Terms & Conditions apply</Typography>
                  </Box>
                </Box>
                <Box className="trendingNowBox">
                  <Typography className="trendingNowText">Trending now</Typography>
                  <Button className="viewAllButton">View All</Button>
                </Box>
                <Box
                  className="productListBox"
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    justifyContent: 'center',
                  }}
                >
                  {this.products.map((product, index) => (
                    <Card key={index} className="productCard">
                      <Box className="likeIconBox">
                        <FavoriteBorderIcon sx={{ color: '#FF6F61' }} />
                      </Box>
                      <CardActionArea>
                        <img className='cardsImg' src={product.image} alt={product.title} />
                        <CardContent>
                          <Box className="productTitleRatingBox">
                            <Typography className="productTitle">{product.title}</Typography>
                            <Box className="ratingBox">
                              <Rating value={product.rating} max={1} precision={1} readOnly />
                              <Typography className="rating">{product.ratingText}</Typography>
                            </Box>
                          </Box>
                          <Typography className="productLocationText">{product.location}</Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Typography className="productPriceText">{product.price}</Typography>
                      </CardActions>
                    </Card>
                  ))}
                </Box>
              </Box>
            </CustomBox>
          </HeaderFooterLayout>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start



const CustomBox = styled(Box)({
  '& .mainBox': {
    width: "100vw",
    height: "auto",
  },
  '& .shopImgBox': {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
  },
  '& .shopbgImg': {
    width: "95%",
    height: "auto",
    maxHeight: "100vh",
  },
  '& .imgtextBox': {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  '& .imgMainText': {
    fontSize: "40px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#ffffff",
    '@media (max-width: 1200px)': { fontSize: "32px" },
    '@media (max-width: 768px)': { fontSize: "24px" },
    '@media (max-width: 480px)': { fontSize: "18px" },
  },
  '& .productListBox': {
    padding: "0rem 2rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    '@media (max-width: 768px)': { padding: "0rem 1rem" },
  },
  '& .produMuiOutlinedInput-notchedOutlinectCard': {
    width: "16vw",
    marginBottom: "1rem",
    position: "relative",
    '@media (max-width: 768px)': { width: "30vw" },
    '@media (max-width: 480px)': { width: "40vw" },
  },
  '& .likeIconBox': {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
    zIndex: 1,
  },
  '& .productTitleRatingBox': {
    display: "flex",
    justifyContent: "space-between",
  },
  '& .productTitle': {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: '1rem' },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .carouselContainer': {
    width: "100vw",
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: "2rem",
    alignItems: "center",
    gap: "2rem",
    '@media (max-width: 768px)': {
      gap: "1rem",
      flexDirection: "column"
    }
  },
  '& .arrowIcon': {
    fontSize: "2rem",
    '@media (max-width: 1200px)': {
      fontSize: "1.8rem",
    },
    '@media (max-width: 768px)': {
      fontSize: "1.5rem",
    },
    '@media (max-width: 480px)': {
      fontSize: "1.2rem",
    },
  },
  '& .carouselImage': {
    '@media (max-width: 768px)': {
      width: "40vw"
    },
    '@media (max-width: 1200px)': {
      width: "20vw"
    },
    '@media (max-width: 480px)': {
      width: "60vw"
    },
  },
  '& .ratingBox': {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  },
  '& .rating': {
    fontSize: "12px",
    fontFamily: 'Poppins',
    color: "#78716C",
    '@media (max-width: 480px)': { fontSize: '0.6rem' },
    '@media (max-width: 768px)': { fontSize: '0.7rem' },
  },
  '& .productLocationText': {
    paddingTop: "1rem",
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { paddingTop: "0.5rem" },
    '@media (max-width: 480px)': { fontSize: '0.8rem' },
  },
  '& .productPriceText': {
    fontWeight: 600,
    fontSize: "16px",
    color: "#801188",
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: "14px" },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .shopAllText': {
    fontSize: "24px",
    fontFamily: 'Poppins',
    fontWeight: 600,
    textAlign: "center",
    paddingTop: "2rem",
    '@media (max-width: 768px)': { fontSize: "20px", paddingTop: "1rem" },
  },
  '& .shopItemsBox': {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "3rem 0",
    '@media (max-width: 768px)': { flexDirection: "column", padding: "2rem 0" },
  },
  '& .shopItem': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 1rem",
    '@media (max-width: 768px)': { margin: "1rem 0" },
  },
  '& .cardsImg': {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  '& .shopItemLabel': {
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: "#801187",
    '@media (max-width: 768px)': { fontSize: "16px" },
  },
  '& .newArrivalsBox': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  '& .newArrivalsTitle': {
    fontSize: "32px",
    fontWeight: 400,
    fontFamily: 'Times New Roman',
    color: "white",
    '@media (max-width: 768px)': { fontSize: "24px" },
  },
  '& .functionImg': {
    '@media (max-width: 1350px)': {
      width: "25%"
    }
  },
  '& .shopsImg': {
    '@media (max-width: 1140px)': {
      width: "85%"
    }
  },
  '& .shopsImgText': {
    fontSize: "18px", fontWeight: 600, fontFamily: "Poppins", color: "#801187",
    '@media (max-width: 1350px)': {
      width: "25%"
    }
  },
  '& .newArrivalsDiscount': {
    fontSize: "128px",
    fontWeight: 700,
    fontFamily: 'Times New Roman',
    color: "white",
    '@media (max-width: 768px)': { fontSize: "96px" },
  },
  '& .discountText': {
    fontSize: "76px",
    '@media (max-width: 768px)': { fontSize: "56px" },
  },
  '& .trendingNowBox': {
    padding: "2rem 5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media (max-width: 768px)': { flexDirection: "column", padding: "2rem 1rem" },
  },
  '& .trendingNowText': {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: "20px" },
  },
  '& .viewAllButton': {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: "#44403C",
    textTransform: "none",
    '@media (max-width: 768px)': { fontSize: "14px" },
  },
  '& .shopNowButton': {
    width: "9.6vw",
    height: "3vw",
    background: "#FFFFFF",
    color: "black",
    fontSize: "19px",
    fontWeight: 700,
    fontFamily: 'Times New Roman',
    borderRadius: "8px",
    marginTop: "16px",
    '@media (max-width: 768px)': { width: "50vw", height: "auto", fontSize: "16px" },
  },
  '& .termsConditions': {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: "white",
    paddingTop: "3rem",
    '@media (max-width: 768px)': { fontSize: "12px", paddingTop: "1rem" },
  },
  '& .imgDescText': {
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: "#ffffff",
    lineHeight: "1.5",
    marginTop: "10px",
    wordSpacing: "0.3rem",
    '@media (max-width: 1200px)': { fontSize: "16px" },
    '@media (max-width: 768px)': { fontSize: "14px" },
    '@media (max-width: 480px)': { fontSize: "12px", lineHeight: "1.4", textAlign: "center", padding: "0 10px" },
  },
  '& .responsiveText': {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: "center",
    lineHeight: "1.6",
    padding: "0 2rem",
    '@media (max-width: 1200px)': { fontSize: "14px", padding: "0 1.5rem" },
    '@media (max-width: 768px)': { fontSize: "12px", padding: "0 1rem" },
    '@media (max-width: 480px)': { fontSize: "11px", padding: "0 0.5rem" },
  },
  '& .functionImageBox': {
    width: "100vw",
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: "2rem",
    alignItems: "center",
    '@media (max-width: 768px)': { flexDirection: "column", paddingTop: "1rem" },
  },
});


