import React from "react";

// Customizable Area Start
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography,  Select, MenuItem, styled,  CardActionArea, CardContent, CardActions, Card, Rating ,Radio,Button,Checkbox,TextField} from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { shoppingImg1, shoppingImg2, shoppingImg3, shoppingImg4 } from "./assets";

// Customizable Area End

import ShopController, {Props} from "./ShopController.web";
import { color } from "react-native-reanimated";

export default class ShopByOccassion extends ShopController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  productCards = [
    {
      image: shoppingImg2,
      title: 'Lehenga',
      location: 'Mumbai',
      price: '₹ 46,000',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
      image: shoppingImg4,
      title: 'Lehenga',
      location: 'Mumbai',
      price: '₹ 46,000',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
      image: shoppingImg1,
      title: 'Lehenga',
      location: 'Mumbai',
      price: '₹ 46,000',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
      image: shoppingImg3,
      title: 'Lehenga',
      location: 'Mumbai',
      price: '₹ 46,000',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
        image: shoppingImg3,
        title: 'Lehenga',
        location: 'Mumbai',
        price: '₹ 46,000',
        rating: 1,
        ratingText: '7.5 (678 Ratings)',
      },
      {
        image: shoppingImg3,
        title: 'Lehenga',
        location: 'Mumbai',
        price: '₹ 46,000',
        rating: 1,
        ratingText: '7.5 (678 Ratings)',
      },
  ]
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <CustomFunctionBox>
            <HeaderFooterLayout navigation={this.props.navigation}>
                <Box data-test-id='OccassionContainer' sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Select
                        labelId="time-period-select"
                        value={this.state.selectedValue || ""}
                        onChange={this.handleOccassion}
                        data-test-id="occassion"
                        displayEmpty
                        sx={{
                            marginRight: '16px',
                            border: "1px solid white",
                            '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                        }}
                        renderValue={(selected) => selected || 'Occasion'}
                    >
                        <MenuItem value="Mehendi">
                            <CustomRadio checked={this.state.selectedValue === 'Mehendi'} />
                            Mehendi
                        </MenuItem>
                        <MenuItem value="Haldi">
                            <CustomRadio checked={this.state.selectedValue === 'Haldi'} />
                            Haldi
                        </MenuItem>
                        <MenuItem value="Wedding">
                            <CustomRadio checked={this.state.selectedValue === 'Wedding'} />
                            Wedding
                        </MenuItem>
                        <MenuItem value="Reception">
                            <CustomRadio checked={this.state.selectedValue === 'Reception'} />
                            Reception
                        </MenuItem>
                    </Select>
                    <Select
                        labelId="price-select"
                        className="select"
                        value={this.state.selectedPrice || ""}
                        onChange={this.handlePrice}
                        data-test-id="price"
                        displayEmpty
                        renderValue={(selected) => selected || 'Price'}
                        sx={{}}
                    >
                        <MenuItem value="₹ 0 - ₹ 20,000">
                            <CustomCheckBox checked={this.state.selectedPrice === '₹ 0 - ₹ 20,000'} />
                            ₹ 0 - ₹ 20,000
                        </MenuItem>
                        <MenuItem value="₹ 20,000 - ₹ 40,000">
                            <CustomCheckBox checked={this.state.selectedPrice === '₹ 20,000 - ₹ 40,000'} />
                            ₹ 20,000 - ₹ 40,000
                        </MenuItem>
                        <MenuItem value="₹ 40,000 - ₹ 60,000">
                            <CustomCheckBox checked={this.state.selectedPrice === '₹ 40,000 - ₹ 60,000'} />
                            ₹ 40,000 - ₹ 60,000
                        </MenuItem>
                        <MenuItem value="₹ 60,000 - ₹ 80,000">
                            <CustomCheckBox checked={this.state.selectedPrice === '₹ 60,000 - ₹ 80,000'} />
                            ₹ 60,000 - ₹ 80,000
                        </MenuItem>
                    </Select>

                    <Select
                        className="categorySelect"
                        labelId="category-select"
                        displayEmpty
                        value={this.state.selectedCategory || ""}
                        data-test-id="category"
                        onChange={this.handleCategory}
                        renderValue={(selected) => selected || 'Category'}
                        sx={{
                            marginRight: '16px',
                            border: "1px solid white",
                            '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                        }}
                    >
                        <MenuItem value="Garments">
                            <CustomCheckBox checked={this.state.selectedCategory === 'Garments'} />
                            Garments
                        </MenuItem>
                        <MenuItem value="Accessories">
                            <CustomCheckBox checked={this.state.selectedCategory === 'Accessories'} />
                            Accessories
                        </MenuItem>
                        <MenuItem value="Jewellery">
                            <CustomCheckBox checked={this.state.selectedCategory === 'Jewellery'} />
                            Jewellery
                        </MenuItem>
                        <MenuItem value="Services">
                            <CustomCheckBox checked={this.state.selectedCategory === 'Services'} />
                            Services
                        </MenuItem>
                        <MenuItem value="Decor">
                            <CustomCheckBox checked={this.state.selectedCategory === 'Decor'} />
                            Decor
                        </MenuItem>
                    </Select>

                    <Select
                        labelId="availability-select"
                        value={this.state.selectedAvailability || ""}
                        onChange={this.handleAvailability}
                        data-test-id="availability"
                        displayEmpty
                        sx={{
                            marginRight: '16px',
                            border: "1px solid white",
                            '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                        }}
                        renderValue={(selected) => selected || 'Availability'}
                    >
                        <MenuItem value="In-stock">
                            <Radio
                                checked={this.state.selectedAvailability === 'In-stock'}
                                sx={{ color: 'lightgrey', '&.Mui-checked': { color: '#6200EA' } }}
                            />
                            In Stock
                        </MenuItem>
                        <MenuItem value="Out-of-stock">
                            <Radio
                                checked={this.state.selectedAvailability === 'Out-of-stock'}
                                sx={{ color: 'lightgrey', '&.Mui-checked': { color: '#6200EA' } }}
                            />
                            Out of Stock
                        </MenuItem>
                    </Select>

                    <Select
                        className="ratingsSelect"
                        labelId="ratings-select"
                        displayEmpty
                        value={this.state.selectedRating || ""}
                        data-test-id="rating"
                        onChange={this.handleRating}
                        renderValue={(selected) => selected || 'Rating'}
                        sx={{
                            marginRight: '16px',
                            border: "1px solid white",
                            '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                        }}
                    >
                        <MenuItem value="1 Star">
                            <CustomCheckBox checked={this.state.selectedRating === '1 Star'} />
                            1 Star
                        </MenuItem>
                        <MenuItem value="2 Stars">
                            <CustomCheckBox checked={this.state.selectedRating === '2 Stars'} />
                            2 Stars
                        </MenuItem>
                        <MenuItem value="3 Stars">
                            <CustomCheckBox checked={this.state.selectedRating === '3 Stars'} />
                            3 Stars
                        </MenuItem>
                        <MenuItem value="4 Stars">
                            <CustomCheckBox checked={this.state.selectedRating === '4 Stars'} />
                            4 Stars
                        </MenuItem>
                        <MenuItem value="5 Stars">
                            <CustomCheckBox checked={this.state.selectedRating === '5 Stars'} />
                            5 Stars
                        </MenuItem>
                    </Select>
                    <Select
                        className="citySelect"
                        labelId="city-select"
                        displayEmpty
                        defaultValue=""
                        data-test-id="city"
                        renderValue={(selected) => selected || 'City'}
                        onChange={this.handleCity}
                        sx={{
                            marginRight: '16px',
                            border: "1px solid white",
                            '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                        }}
                    >
                            <TextField
                                placeholder="Search City"
                                variant="standard"
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                sx={{
                                    padding: '0 8px',
                                }}
                            />
                        <MenuItem value="New York">
                            <CustomCheckBox checked={this.state.selectedCity === 'New York'} />
                            New York
                        </MenuItem>
                        <MenuItem value="Los Angeles">
                            <CustomCheckBox checked={this.state.selectedCity === 'Los Angeles'} />
                            Los Angeles
                        </MenuItem>
                        <MenuItem value="Chicago">
                            <CustomCheckBox checked={this.state.selectedCity === 'Chicago'} />
                            Chicago
                        </MenuItem>
                        <MenuItem value="Houston">
                            <CustomCheckBox checked={this.state.selectedCity === 'Houston'} />
                            Houston
                        </MenuItem>
                    </Select>
                    <Select
                        className="sortBySelect"
                        data-test-id='sortBy'
                        labelId="sort-by-select"
                        displayEmpty
                        defaultValue=""
                        renderValue={(selected) => selected || 'Sort by'}
                        onChange={this.handleSortBy}
                        sx={{
                            marginRight: '16px', border: "1px solid white",
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: "none"
                            },

                        }}
                    >
                        <MenuItem value="Price: Low to High">Price: Low to High</MenuItem>
                        <MenuItem value="Price: High to Low">Price: High to Low</MenuItem>
                        <MenuItem value="Rating: Low to High">Rating: Low to High</MenuItem>
                        <MenuItem value="Rating: High to Low">Rating: High to Low</MenuItem>
                    </Select>
                </Box>
                <Box className='occassionTextbox'>
                    <Typography className='occassionText'>{this.state.occassionId}</Typography>
                </Box>
                <Box className='occassionDescriptionBox'>
                    <Typography className='occassionDescriptionText'>The Mehendi ceremony is a vibrant and joyful prelude to your wedding, filled with laughter, music, and the intricate art of henna. To complement this cherished tradition, finding the perfect outfit is key. At Happiffie, we’re here to help you shine in style as you celebrate this beautiful occasion.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                    <Box className="productListBox">
                        {this.productCards.map((product, index) => (
                            <Card
                                key={index}
                                className="productCard"
                            >
                                <Box className="likeIconBox">
                                    <FavoriteBorderIcon sx={{ color: 'white' }} />
                                </Box>
                                <Box
                                    className="overlayButton"
                                >
                                    <Button className='addToCartBtn' variant="contained" style={{ background: "#7F1187", textTransform: "none" }}>
                                        Add to Cart
                                    </Button>
                                </Box>
                                <CardActionArea>
                                    <img className='cardsImg' src={product.image} alt={product.title} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                                    <CardContent>
                                        <Box className="productTitleRatingBox" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography className="productTitle" variant="h6">
                                                {product.title}
                                            </Typography>
                                            <Box className="ratingBox" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Rating value={product.rating} max={1} precision={1} readOnly size="small" />
                                                <Typography className="ratingText" sx={{ marginLeft: '4px', fontSize: '0.875rem' }}>
                                                    {product.ratingText}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography className="productLocation" variant="body2" color="textSecondary">
                                            {product.location}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Typography className="productPrice" variant="h6" color="primary">
                                        {product.price}
                                    </Typography>
                                </CardActions>
                            </Card>
                        ))}
                    </Box>
                </Box>
            </HeaderFooterLayout>
        </CustomFunctionBox>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomRadio = styled(Radio)({
    '&.MuiRadio-root': {
        color: '#64748B !important',
    },
    '& .MuiSvgIcon-root': {
        color:"#6200EA "
    },
  });

const CustomCheckBox = styled(Checkbox)({
    '&.MuiRadio-root': {
        color: '#64748B !important',
    },
    '& .MuiSvgIcon-root': {
        color: "#6200EA "
    },
});

const CustomFunctionBox = styled(Box)({
    '& .likeIconBox': {
        borderRadius: "50%", 
        width: "9%", 
        height: "5%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
         background: "rgba(0, 0, 0, 0.5)",
         position: 'absolute', 
         top: 8, right: 8, zIndex: 2
    },
    '& .radioBtn':{
        
    },
   
    '& .cardsImg': {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
    '& .select': {
        marginRight: '16px',
        border: "1px solid white",
        '& .MuiOutlinedInput-notchedOutline': 
        { border: "none" },
      },
    '& .productTitleRatingBox': {
      display: "flex",
      justifyContent: "space-between",
    },
    '& .occassionDescriptionBox': {
        display: "flex", 
        padding: "2rem 5rem"
      },
      '& .productListBox': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: "2rem",
        justifyContent: 'center',
      },
      '& .addToCartBtn': {
        fontSize: "16px", 
        fontWeight: 500, 
        fontFamily: 'poppins', 
        width: "207px", 
        height: '44px', 
        borderRadius: "30px", 
        background: "#7F1187", 
        color: "white" 
      },
      '& .productCard': {
        position: 'relative',
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: 0.8,
        },
        '&:hover .overlayButton': {
            opacity: 1,
        },
        cursor: 'pointer',
      },
      '& .occassionDescriptionText': {
        fontSize: "16x", 
        fontWeight: 400, 
        fontFamily: "poppins", 
        textAlign: "center", 
        color: "#57534E"
      },
      '& .overlayButton': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        zIndex: 1,
      },
    '& .occassionTextbox': {
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center", 
        alignItems: "center", 
        paddingTop: "2rem" 
      },
      '& .occassionText': {
        fontSize: "40px",
         fontWeight: 500, 
         fontFamily: "poppins"
      },
    '& .productTitle': {
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: 'Poppins',
      '@media (max-width: 768px)': { fontSize: '1rem' },
      '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .ratingText': {
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: 'Poppins',
      color: "#78716C",
      '@media (max-width: 768px)': { fontSize: '0.7rem' },
      '@media (max-width: 480px)': { fontSize: '0.6rem' },
    },
    '& .productLocation': {
      paddingTop: "1rem",
      fontFamily: 'Poppins',
      '@media (max-width: 768px)': { paddingTop: "0.5rem" },
      '@media (max-width: 480px)': { fontSize: '0.8rem' },
    },
     '& .productPrice': {
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: 'Poppins',
      color: "#801188",
      '@media (max-width: 768px)': { fontSize: "14px" },
      '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
  })

// Customizable Area End
