import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall, clearStorageData, redirectToPage } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";
import { CredentialResponse } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

export const baseURL = require("../../../framework/src/config.js");

type loginWebResponseJson = { errors: { failed_login: string }[] } & {
  meta: { token: string; refresh_token: string };
};

interface CrouselData {
  id: number;
  attributes: {
    id: number;
    img_vid_url: {
      type: string;
      id: 23,
      url:string;       
    }
}
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  inValidEmail: boolean;
  inValidPassword: boolean;
  carouselItem:CrouselData[];
  formData: {
    email: string;
    password: string;
    showPassword: boolean;
  };
  isLoading: boolean;
  credentialResponse:string | undefined;
  error:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginEmailApiCallId: string = "";
  getCarouselListCallId:string = "";
  googleApicallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      inValidEmail: false,
      inValidPassword: false,
      carouselItem:[],
      formData: {
        email: "",
        password: "",
        showPassword: false,
      },
      isLoading: false,
      credentialResponse:undefined,
      error:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.carosalData(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) 
      {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webApiRequestCallId === this.loginEmailApiCallId || webApiRequestCallId === this.googleApicallId) 
        {
        this.handleLoginApiResponse(webResponseJson);
       }
      this.setState({isLoading: false});
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.getCarouselApi();
    await clearStorageData();
  };
  changePasswordValid = () => {
    this.setState({ inValidPassword: false });
  };
  changeEmailValid = () => {
    this.setState({ inValidEmail: false });
  };
  goToPhoneLogin = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorPhoneLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message); 
  }
  goToForgotPasswordPage = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorForgotPassword");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }
  loginWithVendorEmail = async (values: { email: string; password: string }) => {
    this.setState({
      isLoading: true,
    });
    const bodyDate = {
      type: "email_account",
      attributes: {
        email: values.email.trim(),
        password: values.password,
      },
    };
    this.loginEmailApiCallId = await apiCall({
      endPoint: "bx_block_login/logins",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({ data: bodyDate }),
    });
    // this.redirectFromVendorLoginToHomePage();
  };

  carosalData= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.getCarouselListCallId &&
			this.getCarouselListCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.data){
        this.setState({carouselItem:responseJson.data})
			}
		}
  }
  handleLoginApiResponse = (webResponseJson: loginWebResponseJson) => {
    if (Array.isArray(webResponseJson.errors)) {
      if (webResponseJson.errors[0].failed_login ==="Account not found, or not activated" )
         {this.setState({inValidEmail: true,});
        toast.error("Account is not found or not activated. Please try again.");
        return;
      } else {
        this.setState({
          inValidPassword: true,
        }); toast.error("The password you entered is invalid. Please try again."); return;
      }
    }
    if (webResponseJson.meta.token)
    {
      setStorageData("authToken", webResponseJson.meta.token);
      localStorage.setItem("apiResponse", JSON.stringify(webResponseJson));
      toast.success("Vendor login successfully.");
     this.redirectFromVendorLoginToHomePage();
    }
  };


  getCarouselApi = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const body =null
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.carouselGetApiEndpoint );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCarouselListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  googleLoginApi = async (jwtDecoded:any, credentialResponse:CredentialResponse) => {
    this.setState({ isLoading: true });
    this.googleApicallId = await apiCall({
      endPoint: "/account_block/accounts",
      method: "POST",
      body:JSON.stringify({
        "data" :
        {
            "type" : "social_account",
            "attributes": {
            "first_name": jwtDecoded.given_name,
            "last_name": jwtDecoded.family_name,
             "email": jwtDecoded.email,
             "unique_auth_id": credentialResponse.clientId
            }
        }   
    }),
      contentType: "application/json",
    });
  };

  redirectFromVendorLoginToHomePage = () => {
    redirectToPage(this.props.navigation, "Dashboard");
  };

  googleVendorLoginSuccess = (credentialResponse: CredentialResponse) => {
    this.setState({credentialResponse:credentialResponse.credential})
    const jwtDecoded = credentialResponse.credential && jwtDecode(credentialResponse.credential);
    this.googleLoginApi(jwtDecoded,credentialResponse)
  };

  googleVendorLoginFailed = () => {
    toast.error("Google vendor login failed");
  }
  // Customizable Area End
}
