import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  apiCall,
  clearStorageData,
  redirectToPage,
} from "../../../components/src/utils";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";
import { CredentialResponse } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

export const baseURL = require("../../../framework/src/config.js");

type loginWebResponseJson = { errors: { failed_login: string }[] } & {
  meta: { token: string; refresh_token: string };
};
interface CrouselData {
  id: number;
  attributes: {
    id: number;
    img_vid_url: {
      id: 23,
      type: string;
      url:string;     
    }
}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    email: string;
    password: string;
    showPassword: boolean;
  };
  isLoading: boolean;
  inValidEmail: boolean;
  inValidPassword: boolean;
  dataCarousel:CrouselData[]
  loading:boolean;
  jwtDecoded:any| null,
  credentialResponse:string | undefined;
  error:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoginEmailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginEmailApiCallId: string = "";
  getCaroselListCallId:string ="";
  googleApicallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      inValidPassword: false,
      dataCarousel:[],
      loading:false,
      jwtDecoded: null,
      formData: {
        email: "",
        password: "",
        showPassword: false,
      },
      isLoading: false,
      inValidEmail: false,
      credentialResponse:undefined,
      error:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.carouselList(message)

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (webApiRequestCallId === this.loginEmailApiCallId || webApiRequestCallId === this.googleApicallId) {
        this.handleLoginApiResponse(webResponseJson);
      }
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.carouselApi()
    await clearStorageData();
   
  };

  handleLoginApiResponse = (webResponseJson: loginWebResponseJson) => {

    if (Array.isArray(webResponseJson.errors)) {
      if (
        webResponseJson.errors[0].failed_login ===
        "Account not found, or not activated"
      ) {
        this.setState({
          inValidEmail: true,
        });
        toast.error("Account is not found or not activated. Please try again.");
        return;
      } else {
        this.setState({ inValidPassword: true,
        });
        toast.error("The password you entered is invalid. Please try again.");
        return;
      }
    }
    if (webResponseJson.meta.token) {
      setStorageData("authToken", webResponseJson.meta.token);
      localStorage.setItem("apiResponse", JSON.stringify(webResponseJson));
      toast.success("User login successfully.");
      redirectToPage(this.props.navigation, "LandingPageWeb");
    }
  };

  carouselList= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.getCaroselListCallId &&
			this.getCaroselListCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.data){
        this.setState({dataCarousel:responseJson.data})
			}
		}
  }

  redirectToForgotPasswordPage = () => {
    this.props.navigation.navigate("ForgotPassword");
  };

  redirectToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };

  loginWithEmail = async (values: { email: string; password: string }) => {
    this.setState({
      isLoading: true,
    });
    const bodyDate = {
      type: "email_account",
      attributes: {
        email: values.email.trim(),
        password: values.password,
      },
    };
    this.loginEmailApiCallId = await apiCall({
      endPoint: "bx_block_login/logins",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({ data: bodyDate }),
    });
  };

  changePasswordValid = () => {
    this.setState({ inValidPassword: false });
  };

  changeEmailValid = () => {
    this.setState({ inValidEmail: false });
  };

  goToSignUp = () => {
    this.props.navigation.navigate("EmailAccount");
  };

  carouselApi = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.carouselGetApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); this.getCaroselListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    this.setState({credentialResponse:credentialResponse.credential})
    const jwtDecoded = credentialResponse.credential && jwtDecode(credentialResponse.credential);
    this.googleLoginApi(jwtDecoded,credentialResponse)
  };

  googleLoginFailed = () => {
    toast.error("Google user login failed");
  }
  
  googleLoginApi = async (jwtDecoded:any, credentialResponse:CredentialResponse) => {
    this.setState({ isLoading: true });
    this.googleApicallId = await apiCall({
      endPoint: "/account_block/accounts",
      method: "POST",
      body:JSON.stringify({
        "data" :
        {
            "type" : "social_account",
            "attributes": {
            "first_name": jwtDecoded.given_name,
            "last_name": jwtDecoded.family_name,
             "email": jwtDecoded.email,
             "unique_auth_id": credentialResponse.clientId
            }
        }   
    }),
      contentType: "application/json",
    });
  };



  // Customizable Area End
}
