import React from "react";

// Customizable Area Start
import {
  Box,Typography, Stack,Avatar,InputLabel,styled, Chip,Button,TextField,InputAdornment,IconButton,Autocomplete, MenuItem, Checkbox, ListItemText,
} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import TextInputFiled from "../../../components/src/TextInputField.web";
import { Formik, FormikErrors, FieldArray,FormikTouched} from "formik";
import * as Yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import SelectField from "../../../../packages/components/src/SelectField.web";
export type touched = boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
export type  errors = string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined
interface ColorOption {
  label: string;
  value: number;
}
interface Link{
  link:string
}
interface Product{
  product:string
}
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  helperText = (
    touched: touched,
    errors: errors,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }

  handleDescriptionBox = (values: any, setFieldValue: any, handleBlur: any, touched: FormikTouched<any>, errors: any, handleChange: any) => {
    return(
      <Box sx={webstyle.description}>
      <Box sx={webstyle.innerDescription}>
        <InputLabel className="labelClass" style={webstyle.inputText}>Primary contact number<span style={webstyle.star}>*</span></InputLabel>
        <Box sx={webstyle.formInput2}>
          <SelectField sx={{ width: "79px" }}
            placeholder="+91"
            label=""
            name="phoneId"
            options={this.state.phoneNumberData.map((data: any) => ({
              label: data.value,
              value: data.label,
            }))}
            isDisabled={!this.state.isEditing}
            setFieldValue={setFieldValue} value={values.phoneId} helperText={undefined} handleBlur={handleBlur}                    />
          <TextInputFiled sx={{width: {xs: "100%", sm: "22vw", md: "80%", lg: "15vw"}}}
            name="primaryContact"
            data-test-id="primary-contact"
            placeholder="Enter primary contact number"
            value={values.primaryContact}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={!this.state.isEditing}
            type="text"
            error={this.isError(touched.primaryContact, errors.primaryContact)}
            helperText={this.handleErrorText(touched.primaryContact, errors.primaryContact) ? errors.primaryContact as string : ""}
          />
        </Box>
      </Box>
      <Box sx={webstyle.innerDescription}>
        <InputLabel className="labelClass" style={webstyle.inputText}>Secondary contact number<span style={webstyle.star}>*</span></InputLabel>
        <Box sx={webstyle.formInput2}>
        <SelectField sx={{ width: "79px" }}
            placeholder="+91"
            label=""
            name="phoneIdNo"
            options={this.state.phoneNumberData.map((data: any) => ({
              label: data.value,
              value: data.label,
            }))}
            isDisabled={!this.state.isEditing}
            setFieldValue={setFieldValue} value={values.phoneIdNo} helperText={undefined} handleBlur={handleBlur}                    />
          <TextInputFiled
            sx={{width: {xs: "100%", sm: "23vw", md: "24.5vw", lg: "16vw"}}}
            name="secondaryNumber"
            data-test-id="full-name-input"
            placeholder="Enter secondary contact number"
            value={values.secondaryNumber}
            handleChange={handleChange}
            disabled={!this.state.isEditing}
            handleBlur={handleBlur}
            type="text"
            error={this.isError(touched.secondaryNumber, errors.secondaryNumber)}
            helperText={
              this.handleErrorText(touched.secondaryNumber, errors.secondaryNumber)
                ? (errors.secondaryNumber as string)
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                  >
                    <Button style={{ color: "#7F1187", fontSize: "16px", fontFamily: "poppins", fontWeight: 400, textTransform: "none" }}>Verify</Button>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
    )
  }

  businessDetailsBox = (values: any, setFieldValue: any, handleBlur: any, touched: any, errors: any, handleChange: any) => {
    return(
      <Box>
      <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700, paddingBottom: "1rem" }}>Business Details:</Typography>
      <InputLabel className="labelClass" style={webstyle.inputText}>Service provided<span style={webstyle.star}>*</span></InputLabel>
      <SelectField sx={{width: "80%"}}
                            error={this.isError(
                                touched.serviceProvided,
                                errors.serviceProvided
                            )}
                            touched={touched.serviceProvided}
                            placeholder="Enter service provided"
                            handleBlur={handleBlur}
                            helperText={touched.serviceProvided && errors.serviceProvided}
                            name="serviceProvided"
                            options={this.state.serviceData.map((data) => ({
                                label: data.name,
                                value: data.id,
                            }))}
                            setFieldValue={setFieldValue}
                            value={values.serviceProvided} label={""}                    />
      <InputLabel className="labelClass" style={webstyle.inputText}>Description</InputLabel>
      <TextInputFiled sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="description"
        data-test-id="full-name-input"
        placeholder="vibrant colors, luxurious fabrics, and intricate craftsmanship.."
        value={values.description}
        disabled={!this.state.isEditing}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type="text"
        error={this.isError(touched.description, errors.description)}
        helperText={this.handleErrorText(touched.description, errors.description) ? errors.description as string : ""}
      />
      <FieldArray name="addProduct" data-test-id="FieldArray1">
                {({ push, remove }: { push: (obj: Product) => void; remove: (index: number) => void }) => (
                          <>
                        <Box className="faqAskSection">
                          <Typography className="labelClass" style={webstyle.inputText}>Products Offered</Typography>
                          <Button className="quesBtn" data-test-id="addSizeClick" onClick={() => push({ product: ""})} style={{ color: "#7F1187", fontSize: "14px",fontFamily: "poppins", fontWeight: 600, textTransform: "none" }}>Add</Button>
                        </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {values.addProduct.map((_: any, index: number) => (
                  <Box className="inputFirstSection">
                  <Box className="inputFieldsSection">
                            <TextField
                          name={`addProduct[${index}].product`}
                          placeholder="Enter product"
                          data-test-id="productField"
                          value={values.addProduct[index].product}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{width:"80%"}}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CloseIcon fontSize="small" sx={{ color: "black", cursor:"pointer" }} data-test-id="removeProduct" onClick={() => this.removeproduct(index, setFieldValue, values)}/>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            Boolean(
                              touched?.addProduct?.[index]?.product &&
                              (errors.addProduct?.[index] as FormikErrors<Product>)?.product
                            )
                          }
                          helperText={
                            touched?.addProduct?.[index]?.product &&
                            (errors.addProduct?.[index] as FormikErrors<Product>)?.product
                          }
                          
                        />
                            
                          </Box>
                  </Box>
                  ))}
                </Box>
                </>
              )}
            </FieldArray>
    </Box>
    )

  }

  paymentAndBankingBox=(values: any, setFieldValue: any, handleBlur: any, touched: FormikTouched<any>, errors: any, handleChange: any)=>{
    return(
      <Box>
      <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700 , padding:"1rem 0rem"}}>Payment and Banking:</Typography>
      <InputLabel className="labelClass" style={webstyle.inputText}>Payment and Banking<span style={webstyle.star}>*</span></InputLabel>
      <TextInputFiled sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="paymentMethod"
        data-test-id="full-name-input"
        placeholder="State Bank of India"
        value={values.paymentMethod}
        disabled={!this.state.isEditing}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type="text"
        error={this.isError(touched.paymentMethod, errors.paymentMethod)}
        helperText={this.handleErrorText(touched.paymentMethod, errors.paymentMethod) ? errors.paymentMethod as string : ""}
      />

      <InputLabel className="labelClass" style={webstyle.inputText}>Bank Account Details<span style={webstyle.star}>*</span></InputLabel>
      <TextInputFiled sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
        name="bankAccountDetails"
        data-test-id="full-name-input"
        placeholder="446XXXXXXXXX90"
        value={values.bankAccountDetails}
        disabled={!this.state.isEditing}
        handleChange={handleChange}
        handleBlur={handleBlur}
        type="text"
        error={this.isError(touched.bankAccountDetails, errors.bankAccountDetails)}
        helperText={this.handleErrorText(touched.bankAccountDetails, errors.bankAccountDetails) ? errors.bankAccountDetails as string : ""}
      />


      <Box sx={webstyle.formInput}>
        <Box sx={webstyle.innerDescription}>
          <InputLabel className="labelClass" style={webstyle.inputText}>IFSC code<span style={webstyle.star}>*</span></InputLabel>
          <TextInputFiled sx={{width: {xs: "35vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
            name="ifscCode"
            data-test-id="full-name-input"
            placeholder="SBIN657646"
            value={values.ifscCode}
            disabled={!this.state.isEditing}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            error={this.isError(touched.ifscCode, errors.ifscCode)}
            helperText={this.handleErrorText(touched.ifscCode, errors.ifscCode) ? errors.ifscCode as string : ""}
          />
        </Box>
        <Box sx={webstyle.innerDescription}>
          <InputLabel className="labelClass" style={webstyle.inputText}>Branch Name<span style={webstyle.star}>*</span></InputLabel>
          <TextInputFiled sx={{width: {xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
            name="branchName"
            data-test-id="full-name-input"
            placeholder="Andheri east"
            value={values.branchName}
            disabled={!this.state.isEditing}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            error={this.isError(touched.branchName, errors.branchName)}
            helperText={this.handleErrorText(touched.branchName, errors.branchName) ? errors.branchName as string : ""}
          />
        </Box>
      </Box>
    </Box>
    )
  }

  isError = (
    touched: touched,
    errors: errors,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

   handleErrorText = (touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined, errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,) => {
    return touched && errors
  }

 getHelperText = (isTouched:any, errors:any) => (isTouched && errors ? errors : "");
 

 handleTextField=(values:any,handleChange:any,handleBlur:any,touched:any,errors:any)=>{
  return ( <TextInputFiled sx={{width: {xs: "35vw", sm: "36vw", md: "32vw", lg: "20.5vw"}}}
  name="primaryContactPersonName"
  data-test-id="full-name-input"
  placeholder="Enter your primary name"
  value={values.primaryContactPersonName}
  handleChange={handleChange}
  handleBlur={handleBlur}
  disabled={!this.state.isEditing}
  type="text"
  error={this.isError(touched.primaryContactPersonName, errors.primaryContactPersonName)}
  helperText={this.handleErrorText(touched.primaryContactPersonName, errors.primaryContactPersonName) ? errors.primaryContactPersonName as string : ""}
/>)
 }
 
  cityNames = () => {
    return ([
      { label: 'Delhi', value: 1 },
      { label: 'Jaipur', value: 2 },
      { label: 'Noida', value: 3 },
      { label: 'Lukhnow', value: 4 },
      { label: 'Mumbai', value: 5 },
    ])
  };
  bankAccountDetails = () => {
    return [
      { label: 'Account Holder Name', value: 1 }, 
      { label: 'Account Number', value: 2 },
      { label: 'Bank Name', value: 3 },
      { label: 'IFSC Code', value: 4 },
      { label: 'Account Type', value: 5 },
    ];
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <CustomBox sx={webstyle.mainContainer}>
          <Box sx={webstyle.customBox}>
            <Typography style={{ fontFamily: "poppins", fontSize: "20px", fontWeight: 600, padding: "1.5rem" }}>Business Settings</Typography>
            <Box sx={webstyle.upperSettingHeader}>
              <Box sx={webstyle.settingsHeader}>
              <div>
        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
          <Avatar
            src={this.state.uploadImage ? URL.createObjectURL(this.state.uploadImage) : undefined}
            style={{
              width: '132px',
              height: '132px',
              color: '#A666FF',
              background: '#F0E5FF',
              fontSize: '56px',
              fontWeight: 600,
            }}
          >
            {!this.state.uploadImage && 'H'}
          </Avatar>
        </label>

        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="file-upload"
          type="file"
          data-test-id="handleImageChange"
          onChange={this.handleImageChange}
          disabled={!this.state.isEditing}
        />
      </div>
                     <Typography style={{ fontSize: "16px", fontWeight: 500, fontFamily: "poppins" }}>{this.state.showName}</Typography>
    </Box>
              <Box sx={webstyle.flexInput} onClick={this.handleEdit} data-test-id="handleEdit">
              <Typography style={{ fontFamily:"poppins", fontSize:"16px", fontWeight:600, color:"#801187" }}>Edit</Typography>
              <CreateIcon style={{color:"#801187"}}/>
              </Box>
            </Box>
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={(values) => this.handleEditApiCall(values)}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit} noValidate style={{ padding: "2rem", gap: "0.6rem" }}>
                    <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700 , paddingBottom:"1rem"}}>
                      Basic Information:
                    </Typography>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Business/Individual Name <span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="businessName"
                      data-test-id="full-name-input"
                      placeholder="Enter your business name"
                      value={values.businessName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      disabled={!this.state.isEditing}
                      error={this.isError(touched.businessName, errors.businessName)}
                      helperText={this.handleErrorText(touched.businessName, errors.businessName) ? errors.businessName as string : ""}
                    />
                    <Box sx={webstyle.formInput}>
                      <Box>
                        <InputLabel className="labelClass" style={webstyle.inputText}>GST Number <span style={webstyle.star}>*</span></InputLabel>
                        <TextInputFiled sx={{width: {xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="gstNumber"
                          data-test-id="full-name-input"
                          placeholder="Enter your GST number"
                          value={values.gstNumber}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          type="text"
                      disabled={!this.state.isEditing}
                      error={this.isError(touched.gstNumber, errors.gstNumber)}
                          helperText={this.handleErrorText(touched.gstNumber, errors.gstNumber) ? errors.gstNumber as string : ""}
                        />
                      </Box>
                      <Box>
                        <InputLabel className="labelClass" style={webstyle.inputText}  >Primary Contact Person Name <span style={webstyle.star}>*</span></InputLabel>
                       {this.handleTextField(values,handleChange,handleBlur,touched,errors)}
                      </Box>

                    </Box>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Email<span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="email"
                      data-test-id="full-name-input"
                      placeholder="Enter your Email"
                      value={values.email}
                      disabled={!this.state.isEditing}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.email, errors.email)}
                      helperText={this.handleErrorText(touched.email, errors.email) ? errors.email as string : ""}
                    />
                  {this.handleDescriptionBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                    <InputLabel className="labelClass" style={webstyle.inputText}>Address<span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="address"
                      data-test-id="full-name-input"
                      placeholder="Enter your address"
                      value={values.address}
                      handleChange={handleChange}
                      disabled={!this.state.isEditing}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.address, errors.address)}
                      helperText={this.handleErrorText(touched.address, errors.address) ? errors.address as string : ""}
                    />
                    <Box sx={webstyle.formInput}>
                    <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>Country<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{width: {xs: "35vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="country"
                          data-test-id="getAllStateApi"
                          value={values.country}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          options={this.state.countryData}
                          isDisabled={!!!this.state.isEditing}
                          placeholder="India"
                          onChange={(value) => this.getAllStateApi(value)}
                          helperText={this.handleErrorText(touched.country, errors.country) ? errors.country as string : ""}
                          label={""}
                          error={Boolean(this.handleErrorText(touched?.country, errors?.country))}
                              touched={touched.country}
                        />
                      </Box>
                      
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>State<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{width: {xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="state"
                          data-test-id="getCityApiCall"
                          value={values.state}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          isDisabled={!this.state.isEditing || values.country ==""}
                          options={this.state.stateData}
                          onChange={(value) => this.getCityApiCall(values.country,value)}
                          placeholder="Maharashtra"
                          helperText={this.handleErrorText(touched.state, errors.state) ? errors.state as string : ""}
                          label={""}
                          error={Boolean(this.handleErrorText(touched?.state, errors?.state))}
                              touched={touched.state}
                        />
                      </Box>
                    </Box>
                    <Box sx={webstyle.formInput}>
                    <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>City<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{width: {xs: "100px", sm: "35.5vw", md: "33vw", lg: "20.5vw"}}}
                          name="city"
                          data-test-id="monthCheck"
                          value={values.city}
                          setFieldValue={setFieldValue}
                          isDisabled={!this.state.isEditing || values.state ==""}
                          handleBlur={handleBlur}
                          options={this.state.cityData || []}
                          placeholder="Mumbai"
                          helperText={this.handleErrorText(touched.city, errors.city) ? errors.city as string : ""}
                          label={""}
                          error={Boolean(this.handleErrorText(touched?.city, errors?.city))}
                              touched={touched.city}
                        />
                      </Box>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>Pin Code<span style={webstyle.star}>*</span></InputLabel>
                         <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="pincode"
                      data-test-id="monthCheck"
                      placeholder="Enter your pincode"
                      value={values.pincode}
                      handleChange={handleChange}
                      disabled={!this.state.isEditing}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.pincode, errors.pincode)}
                      helperText={this.handleErrorText(touched.pincode, errors.pincode) ? errors.pincode as string : ""}
                    />
                      </Box>
                    </Box>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Service Location<span style={webstyle.star}>*</span></InputLabel>
                    <div>
                            <Autocomplete
                              multiple sx={{width: "80%"}}
                              id="tags-checkbox"
                              options={this.state.locationData}
                              value={this.state.selectedLocations}
                              data-test-id="handleSelectLocationChange"
                              onChange={(event, newValue) => this.handleSelectLocationChange(event, newValue, setFieldValue)}
                              disableCloseOnSelect
                              renderTags={() => null}
                              renderOption={(props, option: ColorOption) => {
                                const isSelected = this.state.selectedLocations.some(selected => selected.value === option.value);
                                return (                                    
                                    <MenuItem
            {...props}
            key={option.value}
            onClick={() => {
              this.toggleLocationOption(option);
              this.handleLocationOption(option, isSelected, setFieldValue);
            }}
            style={{ borderBottom: '1px solid #E7E5E4' }}
          ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                                    <ListItemText
                                      primary={
                                        option.label}
                                    /></MenuItem>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  data-test-id="handleChangeLocation"
                                  placeholder="Select the location where you can deliver your products for Outfit"
                                  onChange={(event) => handleChange(event)}
                                  onBlur={handleBlur}
                                  value={values.selectedLocation}
                                  error={this.isError(touched?.selectedLocation, errors?.selectedLocation)}
                              helperText={this.helperText(
                          touched?.selectedLocation,
                          errors?.selectedLocation
                        )}
                                />
                              )}
                            />
                          </div>

                          <Stack direction="row" spacing={1} className="chipBox">
                            {this.state.selectedLocations.map((option) => (
                              <Chip
                                key={option.value}
                                label={option.label}
                                style={{ margin: '4px' }}
                                data-test-id="handleDeleteLocationChip"
                                onDelete={() => this.handleDeleteLocationChip(option, setFieldValue)}
                                deleteIcon={
                                  <IconButton size="small">
                                    <CloseIcon fontSize="small" className="closeIcon" />
                                  </IconButton>
                                }

                              />
                            ))}
                          </Stack>

                    <InputLabel className="labelClass" style={{paddingTop:"1rem", fontSize:"14px", fontWeight:500,fontFamily:"poppins",color:"#334155"}}>Portfolio link (optional)</InputLabel>
                    <TextInputFiled  sx={{width: {xs: "100%", sm: "90%", md: "80%", lg: "43vw"}}}
                      name="portfolioLink"
                      data-test-id="full-name-input"
                      placeholder="htttp://portfolio.srivari.co.in"
                      handleChange={handleChange}
                      value={values.portfolioLink}
                      disabled={!this.state.isEditing}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.portfolioLink, errors.portfolioLink)}
                      helperText={this.handleErrorText(touched.portfolioLink, errors.portfolioLink) ? errors.portfolioLink as string : ""}
                    />

                     <FieldArray name="addLink" data-test-id="FieldArray2">
                {({ push, remove }: { push: (obj: Link) => void; remove: (index: number) => void }) => (
                          <>
                        <Box className="faqAskSection">
                          <Typography className="labelClass" style={webstyle.inputText}>Social Media Link</Typography>
                          <Button className="quesBtn" data-test-id="addSizeClick" onClick={() => push({ link: ""})} style={{ color: "#7F1187", fontSize: "14px",fontFamily: "poppins", fontWeight: 600, textTransform: "none" }}>Add</Button>
                        </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {values.addLink.map((_, index) => (
                  <Box className="inputFirstSection">
                  <Box className="inputFieldsSection">
                            <TextField
                          name={`addLink[${index}].link`}
                          placeholder="Enter Link"
                          data-test-id="linkField"
                          value={values.addLink[index].link}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{width:"80%"}}
                          type="text"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CloseIcon fontSize="small" data-test-id="removeLink" sx={{ color: "black", cursor:"pointer" }} onClick={() => this.removeLink(index, setFieldValue, values)}/>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            Boolean(
                              touched?.addLink?.[index]?.link &&
                              (errors.addLink?.[index] as FormikErrors<Link>)?.link
                            )
                          }
                          helperText={
                            touched?.addLink?.[index]?.link &&
                            (errors.addLink?.[index] as FormikErrors<Link>)?.link
                          }
                        />
                            
                          </Box>
                  </Box>
                  ))}
                </Box>
                </>
              )}
            </FieldArray>
                  {this.businessDetailsBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                   {this.paymentAndBankingBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                    <Box sx={webstyle.saveButton}>
                      <Button sx={this.state.isEditing ? webstyle.saveClick : webstyle.saveBtn} type="submit" disabled={!this.state.isEditing} data-test-id="handleEditApiCall" >Save changes</Button>
                    </Box>
                  </form>
                )
              }}
            </Formik>
          </Box>
        </CustomBox>
      </HeaderVendorFooter>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  mainContainer:{
    display:"flex", 
    justifyContent:"center",
    alignItems:"center",
  },
  customChip:{
    display:"flex",
    gap:"12px"
  },
  upperSettingHeader:{
    display:"flex",
    justifyContent:"space-between"
  },
  saveButton:{
    padding:"2rem 1rem"
  },
  saveBtn:{
    width: "172px",
   height: "44px", 
   borderRadius: "33px", 
   background: "#F1F5F9", 
   color: "#64748B", 
   textTransform: "none", 
   fontWeight:500, 
   fontSize:"16px", 
   fontFamily:"poppins"
  },
  saveClick:{
    width: "172px",
   height: "44px", 
   borderRadius: "33px", 
   background: "#801187", 
   color: "#FFFFFF", 
   textTransform: "none", 
   fontWeight:500, 
   fontSize:"16px", 
   fontFamily:"poppins",
   "&:hover":{
   background: "#801187", 

   }
  },
  flexInput:{
    display:"flex",
    paddingRight: "2rem",
    gap:"12px"
  },
  star: {
   color:"red"
  },
  settingsHeader:{
    display:"flex",
    alignItems:"center",
    gap:"18px",
    paddingLeft:"1.5rem"
  },
  formInput:{
    display:"flex",
    gap:"32px",
    alignItems:"center"
  },
  inputText:{
    fontSize:"14px",
    fontWeight:500,
    fontFamily:"poppins",
    color:"#334155",
  },
  formInput2:{
     display:"flex",
    gap:"16px",
    alignItems:"center"
  },
  description:{
     display:"flex",
     alignItems:"center",
     gap:"32px"
  },
  innerDescription:{
    display:"flex",
    flexDirection:"column"
  },
  customBox: {
   width:"90vw",
   height:"auto",
   border:"1px solid lightgrey",
   borderRadius:"8px"
  },
  settingsDetals:{
    padding:"1.5rem"
  },
  imageBox:{
    height:"25%",
    width:"10%",
    borderRadius:"50%",
    border:"1px solid black"
  }
}
const CustomBox = styled(Box)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .faqAskSection": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%"
  },
  "& .MuiChip-root": {
    border: "2px solid #CA1A7D",
    backgroundColor: "transparent",
    padding: '6px, 8px, 6px, 12px'
  },
  "& .MuiChip-label": {
    color: "#292524",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textTransform: "capitalize"
  },
  "& .closeIcon": {
    color: "black"
  },
})


const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .required('Business name is required')
    .min(2, 'Business name must be at least 2 characters')
    .max(50, 'Business name must not exceed 50 characters'),

  gstNumber: Yup.string()
    .required('GST number is required')
    .matches(/^\d{2}[A-Z]{5}\d{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/, 'Invalid GST number'),

  primaryContactPersonName: Yup.string()
    .required('Primary contact person name is required')
    .min(2, 'Name must be at least 2 characters')
    .max(50, 'Name must not exceed 50 characters'),

  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),

   primaryContact: Yup.string()
    .required('Primary contact number is required')
    .matches(/^\d{10}$/, 'Primary contact must be a 10-digit number'),

  secondaryNumber: Yup.string()
    .matches(/^\d{10}$/, 'Secondary contact must be a 10-digit number')
    .notRequired(),

  address: Yup.string()
    .required('Address is required')
    .max(100, 'Address must not exceed 100 characters'),

  city: Yup.string()
    .required('City is required')
    .min(2, 'City name must be at least 2 characters')
    .max(50, 'City name must not exceed 50 characters'),

  state: Yup.string()
    .required('State is required')
    .min(2, 'State name must be at least 2 characters')
    .max(50, 'State name must not exceed 50 characters'),

  ifscCode: Yup.string()
    .required('IFSC Code is required')
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC Code format'),

  branchName: Yup.string()
  .required('Branch Name is required.'),
  
  country: Yup.string()
    .required('Country is required')
    .min(2, 'Country name must be at least 2 characters')
    .max(50, 'Country name must not exceed 50 characters'),

  pincode: Yup.string()
    .required('Pin Code is required')
    .matches(/^[1-9]\d{5}$/, 'Invalid Pin Code'),

  serviceLocation: Yup.string()
    .max(100, 'Service location must not exceed 100 characters'),

  portfolioLink: Yup.string()
    .url('Invalid URL format')
    .notRequired(),

    addLink: Yup.array().of(
      Yup.object().shape({
        link: Yup.string()
          .url('Invalid URL format')
          .notRequired(),
      })
    ),
    paymentMethod: Yup.string()
    .required('The Field is required'),

    bankAccountDetails: Yup.string()
    .required('The Field is required'),
    selectedLocation: Yup.string().required("Please select Location"),

});


// Customizable Area End
