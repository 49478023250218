import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showDress:boolean;
  selectedOccasion: string;
   occassionId:string;
   selectedValue:string;
   selectedPrice: string;
   selectedAvailability: string;
   selectedCategory:string;
   selectedRating:string;
   selectedCity:string;
   selectedSortBy:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShopController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showDress:false,
      selectedOccasion: '',
      occassionId:"",
      selectedValue:"",
      selectedPrice:"",
      selectedAvailability:"",
      selectedCategory:"",
      selectedRating:"",
      selectedCity:"",
      selectedSortBy:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    let occassionId = await getStorageData('occassionId')
    this.setState({occassionId: occassionId});
  
  }

  showFunctionDress=(name:string)=>{
    setStorageData("occassionId", name)
    this.props.navigation.navigate("ShopByOccassion", { name });
  }

  handleOccassion = (event: any) => {
    this.setState({ selectedValue: event.target.value as string });
  };

  handlePrice=(event:any)=>{
    this.setState({selectedPrice:event.target.value as string});
  }

  handleAvailability=(event:any)=>{
    this.setState({
      selectedAvailability:event.target.value as string
    })
  }

  handleCategory=(event:any)=>{
    this.setState({selectedCategory:event.target.value as string})
  }
  handleRating=(event:any)=>{
    this.setState({selectedRating:event.target.value as string})
  }
  
  handleCity=(event:any)=>{
    this.setState({selectedCity:event.target.value as string})
  }

  handleSortBy=(event:any)=>{
    this.setState({selectedSortBy:event.target.value as string})
  }

  // Customizable Area End
}
